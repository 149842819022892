.menu-container {
  background-color: aliceblue;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  font-size: larger;
}

.hamburger-icon {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  height: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hamburger-icon span {
  width: 25px;
  height: 3px;
  background-color: rgb(255, 101, 1);
  border-radius: 5px;
  transition: all 0.3s ease;
}

.menu-items {
  display: flex;
  justify-content: unset;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease;
}
.menu-items.active {
  display: block;
}

.menu-items {
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease;
}
.menu-button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 6%;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
}

.menu-button:hover {
  background-color: #007bff;
  color: white; 
  transform: scale(1.1);
}

.logout-button {
  background-color: #dc3545;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  position: absolute;
  right: 10px;
  transition: all 0.3s ease;
}

.logout-button:hover {
  background-color: #dc3545;
  transform: scale(1.1);
}

.user-details {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  position: absolute;
  right: 20px;
  top: -40px;
  transition: all 0.3s ease;
}
@media (max-width: 768px) {
  .menu-container {
    padding: 10px;
    margin-top: 6px;
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-button {
    font-size: 16px;
    margin: 0 5px;
  }

  .logout-button {
    top: 70px;
    right: 12px;
    background-color: none;
    border: none;
    font-weight: bold;
    margin: 5px 0;
    padding: 0;
    border-radius: 0;
    transform: translateX(10px);
  }

  .logout-button:hover {
    background-color: none;
  }

  .user-details {
    font-size: 18px;
    top: 5px;
    right: 10px;
  }
  
  .hamburger-icon {
    display: flex;
  }
  .menu-items {
    display: none;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .menu-items.active {
    display: flex;
  }
  .logout-button {
    margin-left: 10px;
    padding: 0px 4px;
    border-radius: 6%;
    position: absolute;
  }
  .menu-button {
    margin: 0 10px; 
  }
}
@media (max-width: 480px) {
  .menu-button {
    font-size: 16px;
  }

  .hamburger-icon span {
    width: 20px;
    height: 2px;
  }

  .user-details {
    margin-block-start: 1vh;
    font-size: 16px;
    top: 5px;
  }
  .menu-button {
    margin: 0 5px;
  }

  .menu-items {
    margin-top: 10px;
  }
  
}
