  .menu-link {
    background-color: #ffffff; 
    color: rgb(0, 0, 0) !important; 
    font-size: 16px;
    font-weight: bold;
    padding: 8px 12px;
    text-decoration: none !important; 
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin: 5px 8px;
    display: inline-block;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none !important; 
    box-shadow: none !important; 
  }
  
  .menu-link:hover {
    background-color: #cbc7bf;
    transform: scale(1.1); 
  }
  
  .menu-link:focus, 
  .menu-link:active {
    outline: none !important;
    box-shadow: none !important;
    background-color: #cbc7bf !important; 
  }
  
  .menu-link:visited {
    color: rgb(0, 0, 0) !important; 
  }
  
  @media (max-width: 768px) {
    .menu-link {
      font-size: 14px;
      padding: 6px 10px;
    }
  }
  
  @media (max-width: 480px) {
    .menu-link {
      font-size: 12px;
      padding: 4px 8px;
      margin: 3px 5px;
    }
  }
  